import React from 'react'
import { FiAlertTriangle, FiHelpCircle } from 'react-icons/fi'
import BrandIcon from './BrandIcon'

export default function Info({ children, className, onClick, warning = false }) {
  return (
    <div onClick={onClick} className={`flex justify-center items-center max-w-xl m-2 mx-auto text-base px-3 py-2 rounded-md ${warning ? 'bg-red bg-opacity-10 text-red' : 'text-white bg-pink'} ${onClick && 'cursor-pointer'} ${className}`}>
      {warning
        ? <FiAlertTriangle className="relative w-10 h-10 flex-shrink-0 mr-3" style={{ strokeWidth: 1.5 }} />
        : <FiHelpCircle className="relative w-10 h-10 flex-shrink-0 mr-3" style={{ strokeWidth: 1.5 }} />
      }
      <div className="text-left leading-none -ml-1">{children}</div>
    </div>
  )
}
