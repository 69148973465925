import React from 'react'
import { Helmet } from 'react-helmet'
export default function Headers({ title, photo }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || 'Wizito Photo Booth'}</title>
      <link rel="icon" type="image/png" href="/favicon.png" />
      {/* verify Pinterest */}
      <meta name="p:domain_verify" content="3b8fd1835c98628d3884e1dea41d27fb" />
      <meta name="facebook-domain-verification" content="79m961bq8z66qambg14j4lhdplva7v" />
    </Helmet>
  )
}